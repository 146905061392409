import { FC, useState } from 'react'
import { Center, Divider, Flex, FormControl, Input, Text } from '@chakra-ui/react'

import { useUserAgent } from '@/hooks/useUserAgent'
import { LINEButton } from '@/modules/components/LINEButton'
import { MainButton } from '@/modules/components/MainButton'
import { GTMClickItemName } from '@/modules/utils/gtm/types/common'
import { RegistrationRequest } from '@/oas/talent/api'

interface Props {
  isVerifyLoading: boolean
  phoneNumber: string
  setIsConfirm: (value: boolean) => void
  onSmsSubmit: (value: string) => void
  resendSMS: () => void
  lineSubmit: (talent: RegistrationRequest) => Promise<string | undefined>
  talentInformation: RegistrationRequest
}

export const SMSConfirmElm: FC<Props> = ({
  isVerifyLoading,
  phoneNumber,
  setIsConfirm,
  onSmsSubmit,
  resendSMS,
  lineSubmit,
  talentInformation,
}: Props) => {
  const [confirmCode, setConfirmCode] = useState('')
  const { isWebView } = useUserAgent()
  const lineLogin = async () => {
    // WHY: Safari環境だとクリックからwindow.openまでにawaitが挟まっているとポップアップブロックされるため、openしてから書き換える
    const newTab = window.open('')
    const redirectUrl = await lineSubmit(talentInformation)
    if (redirectUrl && newTab) {
      newTab.location.href = redirectUrl
    } else {
      newTab?.close()
    }
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      p="16px"
      border="1px solid var(--border-border-primary, #E2E8F0)"
      borderRadius="8px"
      gap="24px"
    >
      <Text fontSize="16px" lineHeight="150%" fontWeight={700}>
        電話番号（SMS）でログインする
      </Text>
      <Flex flexDirection="column" justifyContent="flex-start" gap="8px">
        <Text fontSize="13px" lineHeight="175%" fontWeight={700}>
          認証コードを入力してください
        </Text>
        <Text fontSize="12px" lineHeight="150%" fontWeight={300}>
          {`${phoneNumber}宛にSMSで送信された認証コードを入力してください。`}
        </Text>
        <FormControl>
          <Input
            autoComplete="one-time-code"
            value={confirmCode}
            h="48px"
            p="10px 16px"
            maxLength={11}
            fontSize="18px"
            lineHeight="28px"
            type="text"
            placeholder="6桁の認証コードを入力"
            _placeholder={{ color: '#A0AEC0' }}
            onChange={(e) => setConfirmCode(e.target.value)}
          />
        </FormControl>
        <MainButton
          id="sign-in-button"
          type="submit"
          width="100%"
          mode="primary"
          onClick={() => onSmsSubmit(confirmCode)}
          isLoading={isVerifyLoading}
          data-gtm-click-item={'registration_agent_step_verification_button' as GTMClickItemName}
        >
          <Text fontSize="16px" fontWeight={700} lineHeight="20px">
            次に進む
          </Text>
        </MainButton>
      </Flex>
      <Text
        id="resend-button"
        color="text.link"
        textAlign="center"
        fontSize="11px"
        lineHeight="150%"
        fontWeight={300}
        cursor="pointer"
        onClick={resendSMS}
      >
        認証コードを再送
      </Text>
      <Divider />
      {!isWebView && <LINEButton w="100%" onClick={lineLogin} />}
      <Center>
        <MainButton
          mode="secondary"
          borderColor="border.primary"
          w="fit-content"
          h="40px"
          p="10px 24px"
          data-gtm-click-item={'registration_agent_back_button' as GTMClickItemName}
        >
          <Text
            fontSize="14px"
            fontWeight={700}
            lineHeight="20px"
            color="text.primary"
            onClick={() => setIsConfirm(false)}
          >
            戻る
          </Text>
        </MainButton>
      </Center>
    </Flex>
  )
}
